/* eslint-disable no-return-assign */
import { Component, createElement } from 'react';
import { isStateLess } from './utils';

export default function createComponent(MaterialUIComponent, mapProps) {
  class InputComponent extends Component {
    getRenderedComponent() {
      return this.component;
    }

    render() {
      return createElement(MaterialUIComponent, {
        ...mapProps(this.props),
        ref: !isStateLess(MaterialUIComponent)
          ? (el) => (this.component = el)
          : null,
      });
    }
  }
  InputComponent.displayName = `ReduxFormMaterialUI${
    MaterialUIComponent.options ? MaterialUIComponent.options.name : 'Component'
  }`;
  return InputComponent;
}
