export const ROOT = '/';
export const SIGNIN = '/signin';
export const SIGNUP = '/signup/:name/:surname/:email';
export const SIGNUP_CONFIRMATION = '/signup/:username';
export const REDIRECT = '/r/:host';

export const FORGOT_PASSWORD = '/reset';
export const FORGOT_PASSWORD_CONFIRMATION = '/reset/:username';
export const RESET_PASSWORD = '/reset-password/:username/:session';
export const CHANGE_PASSWORD = '/change/:username';
export const DASHBOARD = '/dashboard';
export const SETTINGS = '/settings';

export const CUSTOMER_DASHBOARD_ID = '/:account/dashboard';

export const USERS = '/users';
export const ADD_USERS = '/users/add';

export const CUSTOMER = '/customer';
export const CUSTOMERS = '/customers';
export const ADD_CUSTOMERS = '/customers/add';
export const VIEW_CUSTOMER = '/customer/:customerId';
export const VIEW_CUSTOMER_POLICIES = '/customer/:customerId/policies';
export const VIEW_CUSTOMER_USERS = '/customer/:customerId/users';

export const REGISTER_CUSTOMERS = '/register-customers';
export const POLICIES = '/policies';
export const POLICIES_PORTFOLIO = '/policies/:portfolio';

export const INVITE_USER = '/invite/:accountId/:code';

export const JWT_NAME = 'jwt';
