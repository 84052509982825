import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import TextField from 'components/TextField';
import { isLoadingConfirmPassword } from 'containers/Auth/authSlice';
import { required, minLength6, passwordsMatch } from 'common/validators';
import { FORGOT_PASSWORD_CONFIRMATION_FORM as form } from './constants';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 800,
    paddingTop: 18,
    paddingBottom: 18,
    fontSize: 16,
  },
  formControl: {
    '& .MuiInputBase-root': {
      borderRadius: '16px',
      fontWeight: 300,
      fontFamily: 'sans-serif',
    },
  },
  footerLinks: {
    color: '#7D8CB6',
    fontSize: 16,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

function ForgotPasswordConfirmationForm(props) {
  const { handleSubmit, pristine, submitting } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(
    false,
  );
  const loading = useSelector(isLoadingConfirmPassword);
  const classes = useStyles();

  return (
    <form
      autoComplete="off"
      className={classes.form}
      noValidate
      onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <Field
          className={classes.formControl}
          style={{ marginBottom: 30 }}
          component={TextField}
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Email address"
          name="Username"
          type="email"
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          autoComplete="username"
          className={classes.formControl}
          style={{ marginBottom: 30 }}
          component={TextField}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Verification code"
          name="SessionCode"
          placeholder="Verification code"
          type="text"
          validate={[required]}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          autoComplete="new-password"
          className={classes.formControl}
          style={{ marginBottom: 30 }}
          component={TextField}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="New password"
          name="NewPassword"
          placeholder="New password"
          props={{
            type: showPassword ? 'text' : 'password',
          }}
          type="text"
          validate={[required, minLength6]}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          autoComplete="new-password"
          className={classes.formControl}
          component={TextField}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setShowConfirmNewPassword(!showConfirmNewPassword)
                  }
                  tabIndex={-1}>
                  {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Confirm new password"
          name="ConfirmNewPassword"
          placeholder="Confirm new password"
          props={{
            type: showConfirmNewPassword ? 'text' : 'password',
          }}
          type="text"
          validate={[required, minLength6, passwordsMatch]}
          variant="outlined"
        />
      </FormControl>
      <div className={classes.buttons}>
        <Button
          className={classes.submit}
          color="primary"
          fullWidth
          disabled={loading || pristine || submitting}
          size="large"
          endIcon={loading ? <CircularProgress size={30} /> : null}
          type="submit"
          variant="contained">
          Confirm
        </Button>
        <Grid container justify="center">
          <Grid item>
            <p className={classes.footerLinks}>
              <Link to="/">
                <strong>Cancel</strong>
              </Link>
            </p>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}

ForgotPasswordConfirmationForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.any,
  submitting: PropTypes.any,
};

export default reduxForm({
  form,
})(ForgotPasswordConfirmationForm);
