/* eslint-disable indent */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import {
  selectIsAuthenticated,
  // selectUser,
  userHasAuthenticated as userHasAuthenticatedAction,
  userSession as userSessionAction,
} from 'containers/Auth/authSlice';
import Notifier from 'components/Notifier';
// import { redirectSignin } from 'common/redirects';
// import * as GROUPS from 'common/cognitoGroups';
import Routes from './Routes';

// const hostname = window && window.location && window.location.hostname;
// const { protocol } = window.location;

function App(props) {
  const { setUserSession, userHasAuthenticated } = props;
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  // const user = useSelector(selectUser);

  const onLoad = useCallback(async () => {
    try {
      const currentSession = await Auth.currentSession();
      setUserSession(currentSession);
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        // console.log(e);
      }
    }
    setIsAuthenticating(false);
  }, [setUserSession, userHasAuthenticated]);

  useEffect(() => {
    onLoad();
  }, [onLoad, isAuthenticated]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     const { [`cognito:groups`]: groups, profile } = user;
  //     switch (groups[0]) {
  //       case GROUPS.SUPER_ADMIN:
  //         window.location.replace(`${protocol}//${redirectSignin(hostname)}`);
  //         break;
  //       case GROUPS.ADMIN:
  //         window.location.replace(
  //           `${protocol}//${redirectSignin(hostname)}/${profile}`,
  //         );
  //         break;
  //       case GROUPS.CONSULTANT:
  //         window.location.replace(
  //           `${protocol}//${redirectSignin(hostname)}/${profile}`,
  //         );
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [user, isAuthenticated]);

  return (
    !isAuthenticating && (
      <>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
        <Notifier />
      </>
    )
  );
}

App.propTypes = {
  setUserSession: PropTypes.func,
  userHasAuthenticated: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  userHasAuthenticated: (values) =>
    dispatch(userHasAuthenticatedAction(values)),
  setUserSession: (values) => dispatch(userSessionAction(values)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withRouter, withConnect)(App);
