import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Home from 'containers/Home';

// Customers
import * as ROUTES from 'common/routes';

import NotFound from './containers/NotFound';

// Auth
import Signup from './containers/Auth/SignUp';
import SignUpConfirmation from './containers/Auth/SignUpConfirmation';
import SignIn from './containers/Auth/SignIn';
import ForgotPassword from './containers/Auth/ForgotPassword';
import ForgotPasswordConfirmation from './containers/Auth/ForgotPasswordConfirmation';
import ResetForgotPassword from './containers/Auth/ResetForgotPassword';
import ValidateUserInvite from './containers/Auth/ValidateUserInvite';

import Redirect from './containers/Auth/Redirect';
import AppliedRoute from './components/AppliedRoute';
// import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute
        appProps={appProps}
        component={Home}
        exact
        path={ROUTES.ROOT}
      />
      <UnauthenticatedRoute
        appProps={appProps}
        component={SignIn}
        exact
        path={ROUTES.SIGNIN}
      />
      <UnauthenticatedRoute
        appProps={appProps}
        component={Redirect}
        exact
        path={ROUTES.REDIRECT}
      />
      <UnauthenticatedRoute
        appProps={appProps}
        component={Signup}
        exact
        path={ROUTES.INVITE_USER}
      />
      <UnauthenticatedRoute
        appProps={appProps}
        component={SignUpConfirmation}
        exact
        path={ROUTES.SIGNUP_CONFIRMATION}
      />
      <UnauthenticatedRoute
        appProps={appProps}
        component={ForgotPassword}
        exact
        path={ROUTES.FORGOT_PASSWORD}
      />
      <UnauthenticatedRoute
        appProps={appProps}
        component={ForgotPasswordConfirmation}
        exact
        path={ROUTES.FORGOT_PASSWORD_CONFIRMATION}
      />
      <UnauthenticatedRoute
        appProps={appProps}
        component={ResetForgotPassword}
        exact
        path={ROUTES.RESET_PASSWORD}
      />
      <UnauthenticatedRoute
        appProps={appProps}
        component={ValidateUserInvite}
        exact
        path={ROUTES.INVITE_USER}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

Routes.propTypes = {
  appProps: PropTypes.any,
};
