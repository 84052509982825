import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import TextField from 'components/TextField';
import { isLoadingSignUp } from 'containers/Auth/authSlice';
import {
  required,
  minLength6,
  confirmPasswordMatch,
  email,
} from 'common/validators';
import { SIGNUP_FORM as form } from './constants';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 800,
    paddingTop: 18,
    paddingBottom: 18,
    fontSize: 16,
  },
  formControl: {
    '& .MuiInputBase-root': {
      borderRadius: '16px',
      fontWeight: 300,
      fontFamily: 'sans-serif',
    },
  },
  footerLinks: {
    color: '#7D8CB6',
    fontSize: 16,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

function SignUpForm(props) {
  const { handleSubmit, pristine, submitting } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const loading = useSelector(isLoadingSignUp);
  const classes = useStyles();

  return (
    <form autoComplete="off" className={classes.form} onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <Field
          autoComplete="off"
          className={classes.formControl}
          style={{ marginBottom: 30 }}
          component={TextField}
          fullWidth
          // disabled
          label="Name"
          name="name"
          type="text"
          validate={[required]}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          autoComplete="off"
          className={classes.formControl}
          style={{ marginBottom: 30 }}
          component={TextField}
          fullWidth
          // disabled
          label="Surname"
          name="surname"
          type="text"
          validate={[required]}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          autoComplete="off"
          className={classes.formControl}
          style={{ marginBottom: 30 }}
          component={TextField}
          fullWidth
          // disabled
          label="Email address"
          name="email"
          type="email"
          validate={[required, email]}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          autoComplete="off"
          className={classes.formControl}
          style={{ marginBottom: 30 }}
          component={TextField}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Password"
          name="password"
          props={{
            type: showPassword ? 'text' : 'password',
          }}
          type="password"
          validate={[required, minLength6]}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          autoComplete="off"
          className={classes.formControl}
          component={TextField}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  tabIndex={-1}>
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Confirm Password"
          name="confirmPassword"
          props={{
            type: showConfirmPassword ? 'text' : 'password',
          }}
          type="password"
          validate={[required, minLength6, confirmPasswordMatch]}
          variant="outlined"
        />
      </FormControl>

      <Button
        className={classes.submit}
        color="primary"
        disabled={loading || pristine || submitting}
        fullWidth
        size="large"
        endIcon={loading ? <CircularProgress size={30} /> : null}
        type="submit"
        variant="contained">
        Complete profile
      </Button>
      {/* <Grid container>
          <Grid item xs>
            <Button
              color="primary"
              component={Link}
              fullWidth
              to="/"
              variant="text">
              Sign In
            </Button>
          </Grid>
        </Grid> */}
    </form>
  );
}

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.any,
  submitting: PropTypes.any,
};

export default reduxForm({
  form,
})(SignUpForm);
