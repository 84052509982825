/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Redirect extends Component {
  componentDidMount() {
    const { protocol } = window.location;
    const {
      match: {
        params: { host },
      },
    } = this.props;
    window.location.replace(`${protocol}//${decodeURIComponent(host)}`);
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Redirect...</title>
        </Helmet>
      </>
    );
  }
}

export default Redirect;
