import { call, put, takeLatest, select } from 'redux-saga/effects';
// import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import axios from 'axios';
import * as authSlice from 'containers/Auth/authSlice';
import * as API from 'common/api';
import * as ROUTES from 'common/routes';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  // closeSnackbar as closeSnackbarAction,
} from 'components/Notifier/actions';
import * as customerActions from './customerSlice';

export function* registerCustomer(actions) {
  const { idToken } = yield select(authSlice.selectUserSession);
  const options = {
    url: API.CUSTOMERS,
    method: 'post',
    headers: {
      Authorization: idToken.jwtToken,
    },
    data: JSON.stringify(actions.payload),
  };
  try {
    const customerResponse = yield call(axios, options);
    yield put(
      customerActions.registerCustomerSuccess(customerResponse.data.data),
    );
    yield put(push(ROUTES.CUSTOMERS));
    yield put(
      enqueueSnackbarAction({
        message: customerResponse.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: () => null,
        },
      }),
    );
  } catch (error) {
    yield put(customerActions.registerCustomerFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* retrieveCustomers() {
  const { idToken } = yield select(authSlice.selectUserSession);
  const options = {
    url: API.CUSTOMERS,
    headers: {
      Authorization: idToken.jwtToken,
    },
  };
  try {
    const customerResponse = yield call(axios, options);
    yield put(
      customerActions.retrieveCustomersSuccess(customerResponse.data.data),
    );
  } catch (error) {
    yield put(customerActions.retrieveCustomersFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* retrieveCustomer(actions) {
  const { customerId } = actions.payload;
  const { idToken } = yield select(authSlice.selectUserSession);
  const options = {
    url: `${API.CUSTOMERS}/${customerId}`,
    headers: {
      Authorization: idToken.jwtToken,
    },
  };
  try {
    const customerResponse = yield call(axios, options);
    yield put(
      customerActions.retrieveCustomerSuccess(customerResponse.data.data),
    );
  } catch (error) {
    yield put(customerActions.retrieveCustomerFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* removeCustomer() {
  // const { customerId } = actions.payload;
  // const { idToken } = yield select(authSlice.selectUserSession);
  // const options = {
  //     url: `${API.CUSTOMERS}/${customerId}`,
  //     method: 'delete',
  //     headers: {
  //         'Authorization': idToken.jwtToken,
  //     },
  // };
  try {
    // const customerResponse = yield call(axios, options);
    // yield put(customerActions.removeCustomerSuccess(customerResponse.data));
    // console.log(customerResponse);
    // yield put(customerActions.retrieveCustomers());
  } catch (error) {
    yield put(customerActions.removeCustomerFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* updateCustomer(actions) {
  const { idToken } = yield select(authSlice.selectUserSession);
  const { id } = actions.payload;
  const options = {
    url: `${API.CUSTOMERS}/${id}`,
    method: 'put',
    headers: {
      Authorization: idToken.jwtToken,
    },
    data: JSON.stringify(actions.payload),
  };
  try {
    const customerResponse = yield call(axios, options);
    yield put(
      customerActions.updateCustomerSuccess(
        customerResponse.data.data.Attributes,
      ),
    );
    yield put(
      enqueueSnackbarAction({
        message: customerResponse.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: () => null,
        },
      }),
    );
  } catch (error) {
    yield put(customerActions.updateCustomerFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* retrievePolicies(actions) {
  const { customerId } = actions.payload;
  const { idToken } = yield select(authSlice.selectUserSession);
  const options = {
    url: `${API.POLICIES_API}/${customerId}/list/1`,
    headers: {
      Authorization: idToken.jwtToken,
    },
  };
  try {
    const customerResponse = yield call(axios, options);
    yield put(
      customerActions.retrievePoliciesSuccess(
        customerResponse.data.results.Items,
      ),
    );
  } catch (error) {
    yield put(customerActions.retrievePoliciesFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* retrievePolicy(actions) {
  const { id, accountid } = actions.payload;
  const { idToken } = yield select(authSlice.selectUserSession);
  const options = {
    url: `${API.POLICIES_API}/${accountid}/${id}`,
    headers: {
      Authorization: idToken.jwtToken,
    },
  };
  try {
    const customerResponse = yield call(axios, options);
    yield put(
      customerActions.retrievePolicySuccess(customerResponse.data.data.Item),
    );
  } catch (error) {
    yield put(customerActions.retrievePolicyFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* inviteUser(actions) {
  const { idToken } = yield select(authSlice.selectUserSession);
  const options = {
    url: `${API.CUSTOMER_USERS_API}`,
    method: 'post',
    headers: {
      Authorization: idToken.jwtToken,
    },
    data: actions.payload,
  };
  try {
    const securityResponse = yield call(axios, options);
    yield put(customerActions.inviteUserSuccess(securityResponse.data.code));
  } catch (error) {
    yield put(customerActions.inviteUserFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* retrieveCustomerUsers() {
  // console.log(actions);
}

export default function* userSaga() {
  yield takeLatest(customerActions.registerCustomer.type, registerCustomer);
  yield takeLatest(customerActions.retrieveCustomers.type, retrieveCustomers);
  yield takeLatest(customerActions.retrieveCustomer.type, retrieveCustomer);
  yield takeLatest(customerActions.removeCustomer.type, removeCustomer);
  yield takeLatest(customerActions.updateCustomer.type, updateCustomer);

  yield takeLatest(customerActions.retrievePolicies.type, retrievePolicies);
  yield takeLatest(customerActions.retrievePolicy.type, retrievePolicy);

  yield takeLatest(customerActions.inviteUser.type, inviteUser);
  yield takeLatest(
    customerActions.retrieveCustomerUsers.type,
    retrieveCustomerUsers,
  );
}
