/* eslint-disable consistent-return */
import * as GROUPS from 'common/cognitoGroups';
// Console
const localConsoleHost = `${process.env.REACT_APP_LOCAL_CONSOLE_HOST}:${process.env.REACT_APP_CONSOLE_PORT}`;
const devConsoleHost = `${process.env.REACT_APP_DEV_CONSOLE_HOST}`;
const stgConsoleHost = `${process.env.REACT_APP_STG_CONSOLE_HOST}`;
const consoleHost = `${process.env.REACT_APP_PRD_CONSOLE_HOST}`;

// Auth
const localAuthHost = `${process.env.REACT_APP_LOCAL_AUTH_HOST}:${process.env.REACT_APP_AUTH_PORT}`;
const devAuthHost = `${process.env.REACT_APP_DEV_AUTH_HOST}`;
const stgAuthHost = `${process.env.REACT_APP_STG_AUTH_HOST}`;
const authHost = `${process.env.REACT_APP_PRD_AUTH_HOST}`;

// App
const localAppHost = `${process.env.REACT_APP_LOCAL_APP_HOST}:${process.env.REACT_APP_APP_PORT}`;
const devAppHost = `${process.env.REACT_APP_DEV_APP_HOST}`;
const stgAppHost = `${process.env.REACT_APP_STG_APP_HOST}`;
const appHost = `${process.env.REACT_APP_PRD_APP_HOST}`;

export function redirectSigninGroup(hostname, group) {
  if (!hostname || !group) return;
  let host = '';

  switch (hostname) {
    case `${process.env.REACT_APP_LOCAL_CONSOLE_HOST}`:
      host = localConsoleHost;
      break;
    case `${process.env.REACT_APP_DEV_CONSOLE_HOST}`:
      host = devConsoleHost;
      break;
    case `${process.env.REACT_APP_STG_CONSOLE_HOST}`:
      host = stgConsoleHost;
      break;
    case `${process.env.REACT_APP_PRD_CONSOLE_HOST}`:
      host = consoleHost;
      break;
    case `${process.env.REACT_APP_LOCAL_AUTH_HOST}`:
      host = localAuthHost;
      break;
    case `${process.env.REACT_APP_DEV_AUTH_HOST}`:
      host = devAuthHost;
      break;
    case `${process.env.REACT_APP_STG_AUTH_HOST}`:
      host = stgAuthHost;
      break;
    case `${process.env.REACT_APP_PRD_AUTH_HOST}`:
      host = authHost;
      break;
    case `${process.env.REACT_APP_LOCAL_APP_HOST}`:
      host = localAppHost;
      break;
    case `${process.env.REACT_APP_DEV_APP_HOST}`:
      host = devAppHost;
      break;
    case `${process.env.REACT_APP_STG_APP_HOST}`:
      host = stgAppHost;
      break;
    case `${process.env.REACT_APP_PRD_APP_HOST}`:
      host = appHost;
      break;
    default:
      host = hostname;
      break;
  }

  if (host.includes('local')) {
    let local = '';
    switch (group) {
      case GROUPS.SUPER_ADMIN:
        local = localConsoleHost;
        break;
      case GROUPS.ADMIN:
      case GROUPS.CONSULTANT:
        local = localAppHost;
        break;
      default:
        local = localAppHost;
        break;
    }
    return local;
  }
  if (host.includes('dev')) {
    let dev = '';
    switch (group) {
      case GROUPS.SUPER_ADMIN:
        dev = devConsoleHost;
        break;
      case GROUPS.ADMIN:
      case GROUPS.CONSULTANT:
        dev = devAppHost;
        break;
      default:
        dev = devAppHost;
        break;
    }
    return dev;
  }
  if (host.includes('stg')) {
    let stg = '';
    switch (group) {
      case GROUPS.SUPER_ADMIN:
        stg = stgConsoleHost;
        break;
      case GROUPS.ADMIN:
      case GROUPS.CONSULTANT:
        stg = stgAppHost;
        break;
      default:
        stg = stgAppHost;
        break;
    }
    return stg;
  }
  if (!host.includes('stg') && !host.includes('dev') && !host.includes('local')) {
    let prod = '';
    switch (group) {
      case GROUPS.SUPER_ADMIN:
        prod = consoleHost;
        break;
      case GROUPS.ADMIN:
      case GROUPS.CONSULTANT:
        prod = appHost;
        break;
      default:
        prod = appHost;
        break;
    }
    return prod;
  }
}

export function redirectSignin(hostname) {
  if (!hostname) return;
  let host = '';

  switch (hostname) {
    case `${process.env.REACT_APP_LOCAL_CONSOLE_HOST}`:
      host = localConsoleHost;
      break;
    case `${process.env.REACT_APP_DEV_CONSOLE_HOST}`:
      host = devConsoleHost;
      break;
    case `${process.env.REACT_APP_STG_CONSOLE_HOST}`:
      host = stgConsoleHost;
      break;
    case `${process.env.REACT_APP_PRD_CONSOLE_HOST}`:
      host = consoleHost;
      break;
    case `${process.env.REACT_APP_LOCAL_AUTH_HOST}`:
      host = localAuthHost;
      break;
    case `${process.env.REACT_APP_DEV_AUTH_HOST}`:
      host = devAuthHost;
      break;
    case `${process.env.REACT_APP_STG_AUTH_HOST}`:
      host = stgAuthHost;
      break;
    case `${process.env.REACT_APP_PRD_AUTH_HOST}`:
      host = authHost;
      break;
    case `${process.env.REACT_APP_LOCAL_APP_HOST}`:
      host = localAppHost;
      break;
    case `${process.env.REACT_APP_DEV_APP_HOST}`:
      host = devAppHost;
      break;
    case `${process.env.REACT_APP_STG_APP_HOST}`:
      host = stgAppHost;
      break;
    case `${process.env.REACT_APP_PRD_APP_HOST}`:
      host = appHost;
      break;
    default:
      host = hostname;
      break;
  }

  if (host.includes('local')) {
    return localConsoleHost;
  }
  if (host.includes('dev')) {
    return devConsoleHost;
  }
  if (host.includes('stg')) {
    return stgConsoleHost;
  }
  return consoleHost;
}

export function redirectSignOut(host) {
  if (!host) return;
  if (host.includes('local')) {
    return localAuthHost;
  }
  if (host.includes('dev')) {
    return devAuthHost;
  }
  if (host.includes('stg')) {
    return stgAuthHost;
  }
  return authHost;
}
