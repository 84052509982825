/* eslint-disable camelcase */
import { call, put, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
// import { push, replace } from 'connected-react-router';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import * as AuthSlice from 'containers/Auth/authSlice';
import * as ROUTES from 'common/routes';
import * as GROUPS from 'common/cognitoGroups';
import * as API from 'common/api';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  // closeSnackbar as closeSnackbarAction,
} from 'components/Notifier/actions';
import { redirectSigninGroup } from 'common/redirects';

const hostname = window && window.location && window.location.hostname;
const { protocol } = window.location;

const cookies = new Cookies();

export function* signIn(actions) {
  const { Username: email, Password: password } = actions.payload;
  const options = {
    url: API.LOGIN_API,
    method: 'post',
    data: JSON.stringify({ email, password }),
  };
  try {
    const authResponse = yield call(axios, options);
    const {
      data: { data },
    } = authResponse;
    const { AuthenticationResult, ChallengeName, Session } = data;

    switch (ChallengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        yield put(AuthSlice.signInSuccess());
        window.location.replace(
          `/reset-password/${encodeURIComponent(email)}/${Session}`,
        );
        break;

      default:
        yield put(AuthSlice.signInSuccess());
        yield call(loginSuccessSaga, AuthenticationResult);
        yield put(
          enqueueSnackbarAction({
            message: 'Signed in successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              action: () => null,
            },
          }),
        );
        break;
    }
  } catch (error) {
    const { data } = error.response;
    yield put(AuthSlice.signInFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* signUp(actions) {
  const { name, surname, email, account_id, password } = actions.payload;
  try {
    const authResponse = yield Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
        family_name: surname,
        profile: account_id,
        preferred_username: account_id,
      },
    });
    yield put(AuthSlice.signUpSuccess(authResponse));
    // yield put(push(`/signup/${encodeURIComponent(email)}`));
    yield put(
      enqueueSnackbarAction({
        message: `Confirmation email successfully sent`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: () => null,
        },
      }),
    );
  } catch (error) {
    yield put(AuthSlice.signUpFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* confirmSignUp(actions) {
  const { email, confirmationCode } = actions.payload;
  const options = {
    url: API.UPDATE_USER_GROUP_API,
    method: 'post',
    data: {
      groupname: GROUPS.CONSULTANT,
      username: email,
    },
  };

  try {
    const authResponse = yield Auth.confirmSignUp(
      email,
      confirmationCode.trim(),
    );
    yield call(axios, options);
    yield put(AuthSlice.confirmSignUpSuccess(authResponse));
    // yield put(push(ROUTES.SIGNIN));
    window.location.replace(ROUTES.SIGNIN);
    yield put(
      enqueueSnackbarAction({
        message: 'User confirmed successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: () => null,
        },
      }),
    );
  } catch (error) {
    yield put(AuthSlice.confirmSignUpFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* loginSuccessSaga(data) {
  const { IdToken } = data;
  const decoded = JSON.parse(atob(IdToken.split('.')[1]));
  const {
    exp,
    'cognito:groups': groups,
    'custom:customer_uid': customer_uid,
  } = decoded;
  const expires = new Date(exp * 1000);

  yield cookies.set(ROUTES.JWT_NAME, IdToken, {
    expires,
    domain: '.jactrack.co.za',
    path: '/',
    secure: process.env.NODE_ENV === 'production',
  });

  switch (groups[0]) {
    case GROUPS.SUPER_ADMIN: {
      // yield put(
      //   push(
      //     `/r/${encodeURIComponent(redirectSigninGroup(hostname, groups[0]))}`,
      //   ),
      // );
      window.location.replace(
        `${protocol}//${redirectSigninGroup(hostname, groups[0])}`,
      );
      return;
    }
    default: {
      const uri = `${redirectSigninGroup(hostname, groups[0])}/${customer_uid}${
        ROUTES.DASHBOARD
      }`;
      window.location.replace(`${protocol}//${uri}`);
      // yield put(replace(`/r/${encodeURIComponent(uri)}`));
    }
  }
}

export function* resetPassword(actions) {
  const { Username } = actions.payload;
  const options = {
    url: API.FORGOT_PASSWORD_API,
    method: 'post',
    data: JSON.stringify({ email: Username }),
  };
  try {
    const authResponse = yield call(axios, options);
    yield put(AuthSlice.resetPasswordSuccess(authResponse));
    window.location.replace(
      `${ROUTES.FORGOT_PASSWORD}/${encodeURIComponent(Username)}`,
    );
  } catch (error) {
    yield put(AuthSlice.resetPasswordFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}
// K5nudCp!YD*5
export function* confirmPassword(actions) {
  const { Username, SessionCode, NewPassword } = actions.payload;
  const options = {
    url: API.CONFIRM_PASSWORD_API,
    method: 'POST',
    data: JSON.stringify({
      email: Username,
      password: NewPassword,
      code: SessionCode,
    }),
  };
  try {
    const authResponse = yield call(axios, options);
    yield put(AuthSlice.confirmPasswordSuccess(authResponse));
    window.location.replace(ROUTES.SIGNIN);
    yield put(
      enqueueSnackbarAction({
        message: 'Password changed successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: () => null,
        },
      }),
    );
  } catch (error) {
    yield put(AuthSlice.confirmPasswordFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* resetNewPassword(actions) {
  const { Username, session, NewPassword } = actions.payload;
  const options = {
    url: API.CONFIRM_RESET_PASSWORD_API,
    method: 'POST',
    data: JSON.stringify({
      email: Username,
      password: NewPassword,
      session,
    }),
  };
  try {
    const authResponse = yield call(axios, options);
    yield put(AuthSlice.resetNewPasswordSuccess(authResponse));
    window.location.replace(ROUTES.SIGNIN);
    yield put(
      enqueueSnackbarAction({
        message: 'Password changed successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: () => null,
        },
      }),
    );
  } catch (error) {
    yield put(AuthSlice.resetNewPasswordFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* changePassword(actions) {
  // console.log(actions);
  const { OldPassword, NewPassword } = actions.payload;
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const authResponse = yield Auth.changePassword(
      user,
      OldPassword,
      NewPassword,
    );
    yield put(AuthSlice.confirmPasswordSuccess(authResponse));
    window.location.replace(ROUTES.SIGNIN);
    yield put(
      enqueueSnackbarAction({
        message: 'Password changed successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: () => null,
        },
      }),
    );
  } catch (error) {
    yield put(AuthSlice.changePasswordFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export function* validateInvite(actions) {
  const options = {
    url: API.VALIDATE_USER_INVITE_API,
    method: 'post',
    data: actions.payload,
  };
  try {
    const authResponse = yield call(axios, options);
    yield put(AuthSlice.validateInviteSuccess(authResponse.data.data));
    yield put(
      enqueueSnackbarAction({
        message: 'Success',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: () => null,
        },
      }),
    );
  } catch (error) {
    yield put(AuthSlice.validateInviteFailed(error));
    yield put(
      enqueueSnackbarAction({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: () => null,
        },
      }),
    );
  }
}

export default function* userSaga() {
  yield takeLatest(AuthSlice.signIn.type, signIn);
  yield takeLatest(AuthSlice.signUp.type, signUp);
  yield takeLatest(AuthSlice.confirmSignUp.type, confirmSignUp);
  yield takeLatest(AuthSlice.resetPassword.type, resetPassword);
  yield takeLatest(AuthSlice.confirmPassword.type, confirmPassword);
  yield takeLatest(AuthSlice.changePassword.type, changePassword);
  yield takeLatest(AuthSlice.validateInvite.type, validateInvite);
  yield takeLatest(AuthSlice.resetNewPassword.type, resetNewPassword);
}
