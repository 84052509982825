const hostname = window && window.location && window.location.hostname;

// Local
let cognito = {
  REGION: 'eu-west-1',
  USER_POOL_ID: 'eu-west-1_dWUyknRum',
  APP_CLIENT_ID: '6g6e85ov7ssu5kpe8t91j6ec69',
  IDENTITY_POOL_ID: 'eu-west-1:ae08b98a-df9f-4984-a0c7-64daaa929073',
};

if (hostname.includes('local')) {
  cognito = {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_dWUyknRum',
    APP_CLIENT_ID: '6g6e85ov7ssu5kpe8t91j6ec69',
    IDENTITY_POOL_ID: 'eu-west-1:ae08b98a-df9f-4984-a0c7-64daaa929073',
  };
} else {
  cognito = {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_xFFyZhaYF',
    APP_CLIENT_ID: '4hsh1nspotl3ueoukrgi239i5p',
    IDENTITY_POOL_ID: 'eu-west-1:bce1963e-0558-45db-b018-6819eeb6f913',
  };
}

// if (hostname.includes('local')) {
//   // Local
//   cognito = {
//     REGION: 'eu-west-1',
//     USER_POOL_ID: 'eu-west-1_RbpMlQq7h',
//     APP_CLIENT_ID: '3tcia9eooihgbil1r047gflk82',
//     IDENTITY_POOL_ID: 'eu-west-1:8532c898-31f7-4b34-bad8-2131f2eaf130',
//   };
// } else if (hostname.includes('dev')) {
//   // Dev
//   cognito = {
//     REGION: 'eu-west-1',
//     USER_POOL_ID: 'eu-west-1_dWUyknRum',
//     APP_CLIENT_ID: '6g6e85ov7ssu5kpe8t91j6ec69',
//     IDENTITY_POOL_ID: 'eu-west-1:ae08b98a-df9f-4984-a0c7-64daaa929073',
//   };
// } else if (hostname.includes('stg')) {
//   // Stg
//   cognito = {
//     REGION: 'eu-west-1',
//     USER_POOL_ID: 'eu-west-1_RbpMlQq7h',
//     APP_CLIENT_ID: '3tcia9eooihgbil1r047gflk82',
//     IDENTITY_POOL_ID: 'eu-west-1:8532c898-31f7-4b34-bad8-2131f2eaf130',
//   };
// }

export default {
  s3: {
    REGION: 'eu-west-1',
    BUCKET: '',
  },
  cognito,
  cookieStorage: {
    domain: hostname.includes('.jactrack.co.za') ? '.jactrack.co.za' : '.4surity.com',
    // domain: 'localhost',
    path: '/',
    expires: 365,
    secure: false,
  },
};
