import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import store from './store';
import config from './common/config';
import history from './common/history';
import GlobalStyles from './globalStyles';
import './styles.css';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <CssBaseline />
        <GlobalStyles />
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  cookieStorage: {
    domain: config.cookieStorage.domain,
    path: config.cookieStorage.path,
    expires: config.cookieStorage.expires,
    secure: config.cookieStorage.secure,
  },
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
