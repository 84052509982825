import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';
import {
  selectInviteUser,
  signUp as signUpAction,
  isLoadingvalidatingInvite,
  validateInvite as validateInviteAction,
} from 'containers/Auth/authSlice';
import SignUpForm from './SignUpForm';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 47,
    // background: `url('/img/construction.jpeg') no-repeat`,
    backgroundSize: 'cover',
    margin: '0 auto',
    maxWidth: '100%',
    backgroundAttachment: 'fixed',
  },
  pageLogo: {
    margin: '0 auto 47px',
    maxWidth: 183,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 560,
    margin: '0 auto 100px',
    padding: '39px 67px',
    transition: 'all ease 300ms',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 26px',
    },
    borderRadius: 24,
  },
  paperTitle: {
    fontWeight: 800,
    fontSize: 30,
  },
  paperSubTitle: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 37,
  },
  inviteLoader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  inviteLoaderTitle: {
    fontWeight: 800,
    fontSize: 30,
    color: 'white',
    marginBottom: 15,
  },
}));

function SignUp(props) {
  const { signUp, validateInvite } = props;
  const { accountId, code } = useParams();
  const loading = useSelector(isLoadingvalidatingInvite);
  // const inviteUser = {
  //   code: '1234567890',
  //   accountId: '180-991-308',
  //   surname: 'Legodi',
  //   email: 'sollymalesela@gmail.com',
  //   name: 'Solly',
  //   status: 'pending',
  // };
  const inviteUser = useSelector(selectInviteUser);
  const classes = useStyles();

  useEffect(() => {
    validateInvite({ accountId, code });
  }, [code, accountId, validateInvite]);

  function handleSubmit(values) {
    signUp(values);
  }

  return (
    <Container component="main" className={classes.page}>
      <img
        className={classes.pageLogo}
        src="/img/logo-alt.png"
        alt="4Surity"
      />
      {!loading ? null : (
        <div className={classes.inviteLoader}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.inviteLoaderTitle}>
            Validating invite
          </Typography>
          {loading ? (
            <div className={classes.progress}>
              <CircularProgress size={50} />
            </div>
          ) : null}
        </div>
      )}

      {!loading && inviteUser ? (
        <Paper elevation={1} className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            gutterBottom
            className={classes.paperTitle}>
            Set your password
          </Typography>
          <SignUpForm initialValues={inviteUser} onSubmit={handleSubmit} />
        </Paper>
      ) : null}
    </Container>
  );
}

SignUp.propTypes = {
  signUp: PropTypes.func,
  validateInvite: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  signUp: (values) => dispatch(signUpAction(values)),
  validateInvite: (values) => dispatch(validateInviteAction(values)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(SignUp);
