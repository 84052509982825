import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dashboard from 'containers/Dashboard';
import { SIGNIN } from 'common/routes';

export default function Home(props) {
  return props.isAuthenticated ? <Dashboard /> : <Redirect to={SIGNIN} />;
}

Home.propTypes = {
  isAuthenticated: PropTypes.bool,
};
