import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
// import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import FormHelperText from '@material-ui/core/FormHelperText';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FiberPin } from '@material-ui/icons';
import TextField from 'components/TextField';
import { isLoadingConfirmSignUp } from 'containers/Auth/authSlice';
import { required, email } from 'common/validators';
import { SIGNUP_CONFIRMATION_FORM as form } from './constants';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 540,
  },
  card: {
    padding: '2em',
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignUpConfirmationForm(props) {
  const { handleSubmit, pristine, submitting } = props;

  const loading = useSelector(isLoadingConfirmSignUp);
  const classes = useStyles();

  return (
    <form autoComplete="off" className={classes.form} onSubmit={handleSubmit}>
      <Card variant="outlined" className={classes.card}>
        <Typography component="h1" variant="h5">
          Verify your profile
        </Typography>
        <Typography component="p" variant="body1">
          Please check your email for the verification code.
        </Typography>
        <FormControl fullWidth>
          <Field
            autoComplete="off"
            className={classes.textField}
            component={TextField}
            disabled
            fullWidth
            label="Email address"
            name="email"
            type="email"
            validate={[required, email]}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth>
          <Field
            aria-describedby="component-help-text"
            autoComplete="off"
            className={classes.textField}
            component={TextField}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiberPin />
                </InputAdornment>
              ),
            }}
            label="Confirmation Code"
            name="confirmationCode"
            type="text"
            validate={[required]}
            variant="outlined"
          />
        </FormControl>
        <Button
          className={classes.submit}
          color="primary"
          disabled={loading || pristine || submitting}
          fullWidth
          size="large"
          endIcon={loading ? <CircularProgress size={30} /> : null}
          type="submit"
          variant="contained">
          Verify
        </Button>
      </Card>
    </form>
  );
}

SignUpConfirmationForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.any,
  submitting: PropTypes.any,
};

export default reduxForm({
  form,
})(SignUpConfirmationForm);
