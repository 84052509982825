import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import TextField from 'components/TextField';
import { isLoadingSignIn } from 'containers/Auth/authSlice';
import { required, email } from 'common/validators';
import { SIGNIN_FORM as form } from './constants';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 800,
    paddingTop: 18,
    paddingBottom: 18,
    fontSize: 16,
  },
  formControl: {
    '& .MuiInputBase-root': {
      borderRadius: '16px',
      fontWeight: 300,
      fontFamily: 'sans-serif',
    },
  },
  footerLinks: {
    color: '#7D8CB6',
    fontSize: 16,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

function SignInForm(props) {
  const { handleSubmit, pristine, submitting } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const loading = useSelector(isLoadingSignIn);
  const classes = useStyles();

  return (
    <form
      autoComplete="off"
      noValidate
      className={classes.form}
      onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <Field
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.formControl}
          style={{ marginBottom: 30 }}
          component={TextField}
          fullWidth
          label="Enter email address"
          name="Username"
          type="email"
          validate={[required, email]}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          autoComplete="off"
          className={classes.formControl}
          component={TextField}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Password"
          name="Password"
          props={{
            type: showPassword ? 'text' : 'password',
          }}
          type="password"
          validate={[required]}
          variant="outlined"
        />
      </FormControl>
      <Button
        className={classes.submit}
        color="primary"
        disabled={loading || pristine || submitting}
        fullWidth
        endIcon={loading ? <CircularProgress size={30} /> : null}
        type="submit"
        variant="contained">
        Login
      </Button>
      <Grid container justify="center">
        <Grid item>
          <p className={classes.footerLinks}>
            {'Forgot password? '}{' '}
            <Link to="/reset">
              <strong>Reset password</strong>
            </Link>
          </p>
        </Grid>
      </Grid>
    </form>
  );
}

SignInForm.propTypes = {
  handleSubmit: PropTypes.any,
  pristine: PropTypes.any,
  submitting: PropTypes.any,
};

export default reduxForm({
  form,
})(SignInForm);
