import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  isLoadingvalidatingInvite,
  validateInvite as validateInviteAction,
} from 'containers/Auth/authSlice';

const useStyles = makeStyles((theme) => ({
  page: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 47,
    // background: `url('/img/construction.jpeg') no-repeat`,
    backgroundSize: 'cover',
    margin: '0 auto',
    maxWidth: '100%',
    backgroundAttachment: 'fixed',
  },
  pageLogo: {
    margin: '0 auto 47px',
    maxWidth: 183,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 560,
    margin: '0 auto 100px',
    padding: '39px 67px',
    transition: 'all ease 300ms',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 26px',
    },
    borderRadius: 24,
  },
  paperTitle: {
    fontWeight: 800,
    fontSize: 30,
    color: 'white',
  },
  paperSubTitle: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 37,
  },
}));

function ValidateUserInvite(props) {
  const { validateInvite } = props;
  const { accountId, code } = useParams();
  const loading = useSelector(isLoadingvalidatingInvite);
  const classes = useStyles();

  useEffect(() => {
    validateInvite({ accountid: accountId, code });
  }, [code, accountId, validateInvite]);

  return (
    <Container component="main" className={classes.page}>
      <img
        className={classes.pageLogo}
        src="/img/logo-alt.png"
        alt="4Surity"
      />
      <Paper elevation={1} className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.paperTitle}>
          Validating invite
        </Typography>
        {loading ? (
          <div className={classes.progress}>
            <CircularProgress size={50} />
          </div>
        ) : null}
      </Paper>
    </Container>
  );
}

ValidateUserInvite.propTypes = {
  validateInvite: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  validateInvite: (values) => dispatch(validateInviteAction(values)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(ValidateUserInvite);
