import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    margin: '20% auto',
  },
});

export default function Home() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    </Container>
  );
}
