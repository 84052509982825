const API_ROOT = 'https://api.jactrack.co.za';

// Login 🔑
export const LOGIN_API = `${API_ROOT}/accounts/v1/signin`;

// export const PROFILE = `${API_ROOT}/profile`;

// // Register 🔑
// export const REGISTER_API = `${PROFILE}/register`;

// // Change Password 🔑
// export const CHANGE_PASSWORD_API = `${PROFILE}/register/change-password`;

// // Forgot Password 🔑
export const FORGOT_PASSWORD_API = `${API_ROOT}/accounts/v1/manage/forgot-password`;

// // Forgot Password Confirmation 🔑
export const CONFIRM_PASSWORD_API = `${API_ROOT}/accounts/v1/manage/reset-password`;

// // Forgot Password Confirmation 🔑
export const CONFIRM_RESET_PASSWORD_API = `${API_ROOT}/accounts/v1/users/invite/confirm`;

// // Groups 👨‍🏫
// export const GROUPS_API = `${API_ROOT}/groups`;

// // CUSTOMERS 🏘️
export const CUSTOMER = `${API_ROOT}/customer`;
export const CUSTOMERS = `${API_ROOT}/customers`;
export const CUSTOMER_USERS_API = `${CUSTOMERS}/users/invite`; // get /${accId}

// Policies 🖇️
export const POLICIES_API = `${API_ROOT}/policies`;

// Invite user 😃
export const INVITE_USER_API = `${API_ROOT}/security/users/invite`;
export const UPDATE_USER_GROUP_API = `${API_ROOT}/users/groups/adduser`;
// export const VALIDATE_USER_INVITE_API = `${API_ROOT}/security/users/validate-invite`;
export const VALIDATE_USER_INVITE_API = `${API_ROOT}/customers/users/invite/validate`;
