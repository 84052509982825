import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { confirmSignUp as confirmSignUpAction } from 'containers/Auth/authSlice';

import SignUpConfirmationForm from './SignUpConfirmationForm';

const useStyles = makeStyles((theme) => ({
  page: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 47,
    // background: `url('/img/construction.jpeg') no-repeat`,
    backgroundSize: 'cover',
    margin: '0 auto',
    maxWidth: '100%',
    backgroundAttachment: 'fixed',
  },
  pageLogo: {
    margin: '0 auto 47px',
    maxWidth: 183,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 560,
    margin: '0 auto 100px',
    padding: '39px 67px',
    transition: 'all ease 300ms',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 26px',
    },
    borderRadius: 24,
  },
  paperTitle: {
    fontWeight: 800,
    fontSize: 30,
    color: 'white',
  },
  paperSubTitle: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 37,
  },
}));

function SignUpConfirmation(props) {
  const { confirmSignUp } = props;
  const classes = useStyles();
  const { username } = useParams();

  function handleSubmit(values) {
    confirmSignUp(values);
  }

  return (
    <Container component="main" className={classes.page}>
      <img
        className={classes.pageLogo}
        src="/img/logo-alt.png"
        alt="4Surity"
      />
      <div className={classes.paper}>
        <SignUpConfirmationForm
          initialValues={{
            email: decodeURIComponent(username),
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </Container>
  );
}

SignUpConfirmation.propTypes = {
  confirmSignUp: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  confirmSignUp: (values) => dispatch(confirmSignUpAction(values)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(SignUpConfirmation);
