import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from 'components/TextField';
import { required, email } from 'common/validators';
import { isLoadingResetPassword } from 'containers/Auth/authSlice';
import { FORGOT_PASSWORD_FORM as form } from './constants';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 800,
    paddingTop: 18,
    paddingBottom: 18,
    fontSize: 16,
  },
  formControl: {
    '& .MuiInputBase-root': {
      borderRadius: '16px',
      fontWeight: 300,
      fontFamily: 'sans-serif',
    },
  },
  footerLinks: {
    color: '#7D8CB6',
    fontSize: 16,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

function ForgotPasswordForm(props) {
  const { handleSubmit, pristine, submitting } = props;
  const loading = useSelector(isLoadingResetPassword);
  const classes = useStyles();

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.form}
      onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <Field
          autoComplete="username"
          component={TextField}
          fullWidth
          classes={{
            root: classes.formControl,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          label="Email address"
          name="Username"
          placeholder="Enter email address"
          type="email"
          validate={[required, email]}
          variant="outlined"
        />
      </FormControl>
      <Button
        className={classes.submit}
        color="primary"
        disabled={loading || pristine || submitting}
        fullWidth
        endIcon={loading ? <CircularProgress size={30} /> : null}
        type="submit"
        variant="contained">
        Reset Password
      </Button>
      <Grid container justify="center">
        <Grid item>
          <p className={classes.footerLinks}>
            {'I made a mistake '}{' '}
            <Link to="/">
              <strong>Login</strong>
            </Link>
          </p>
        </Grid>
      </Grid>
    </form>
  );
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.any,
  submitting: PropTypes.any,
};

export default reduxForm({
  form,
})(ForgotPasswordForm);
