/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    retrieving: false,
    removingCustomer: false,
    updatingCustomer: false,
    retrievingPolicies: false,
    retrievingPolicy: false,
    invitingUser: false,
    customer: {},
    users: [],
    policy: {},
    customers: [],
    policies: [],
  },
  reducers: {
    registerCustomer: (state) => {
      state.registering = true;
    },
    registerCustomerSuccess: (state) => {
      state.registering = false;
    },
    registerCustomerFailed: (state) => {
      state.registering = false;
    },
    retrieveCustomers: (state) => {
      state.retrieving = true;
    },
    retrieveCustomersSuccess: (state, action) => {
      state.retrieving = false;
      state.customers = action.payload;
    },
    retrieveCustomersFailed: (state) => {
      state.retrieving = false;
    },
    retrieveCustomer: (state) => {
      state.retrievingCustomer = true;
    },
    retrieveCustomerSuccess: (state, action) => {
      state.retrievingCustomer = false;
      state.customer = action.payload;
    },
    retrieveCustomerFailed: (state) => {
      state.retrievingCustomer = false;
    },
    removeCustomer: (state) => {
      state.removingCustomer = true;
    },
    removeCustomerSuccess: (state) => {
      state.removingCustomer = false;
    },
    removeCustomerFailed: (state) => {
      state.removingCustomer = false;
    },
    updateCustomer: (state) => {
      state.updatingCustomer = true;
    },
    updateCustomerSuccess: (state, action) => {
      state.updatingCustomer = false;
      state.customer = action.payload;
    },
    updateCustomerFailed: (state) => {
      state.updatingCustomer = false;
    },
    retrievePolicies: (state) => {
      state.retrievingPolicies = true;
    },
    retrievePoliciesSuccess: (state, action) => {
      state.retrievingPolicies = false;
      state.policies = action.payload;
    },
    retrievePoliciesFailed: (state) => {
      state.retrievingPolicies = false;
    },
    retrievePolicy: (state) => {
      state.retrievingPolicy = true;
    },
    retrievePolicySuccess: (state, action) => {
      state.retrievingPolicy = false;
      state.policy = action.payload;
    },
    retrievePolicyFailed: (state) => {
      state.retrievingPolicy = false;
    },
    inviteUser: (state) => {
      state.invitingUser = true;
    },
    inviteUserSuccess: (state) => {
      state.invitingUser = false;
    },
    inviteUserFailed: (state) => {
      state.invitingUser = false;
    },
    retrieveCustomerUsers: (state) => {
      state.retrievingCustomerUsers = true;
    },
    retrieveCustomerUsersSuccess: (state, action) => {
      state.retrievingCustomerUsers = false;
      state.users = action.payload;
    },
    retrieveCustomerUsersFailed: (state) => {
      state.retrievingCustomerUsers = false;
    },
  },
});

export const selectCustomer = (state) => state.customer.customer;
export const selectCustomerUsers = (state) => state.customer.users;
export const selectCustomers = (state) => state.customer.customers;
export const selectPolicies = (state) => state.customer.policies;
export const selectPolicy = (state) => state.customer.policy;
export const isRegistering = (state) => state.customer.registering;
export const isRetrieving = (state) => state.customer.retrieving;
export const isRetrievingCustomer = (state) =>
  state.customer.retrievingCustomer;
export const isRemovingCustomer = (state) => state.customer.removingCustomer;
export const isUpdatingCustomer = (state) => state.customer.updatingCustomer;
export const isRetrievingPolicies = (state) =>
  state.customer.retrievingPolicies;
export const isRetrievingPolicy = (state) => state.customer.retrievingPolicy;
export const isInvitingUser = (state) => state.customer.invitingUser;
export const isRetrievingCustomerUsers = (state) =>
  state.customer.retrievingCustomerUsers;

export const {
  registerCustomer,
  registerCustomerSuccess,
  registerCustomerFailed,
  retrieveCustomers,
  retrieveCustomersSuccess,
  retrieveCustomersFailed,
  retrieveCustomer,
  retrieveCustomerSuccess,
  retrieveCustomerFailed,
  removeCustomer,
  removeCustomerSuccess,
  removeCustomerFailed,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFailed,
  retrievePolicies,
  retrievePoliciesSuccess,
  retrievePoliciesFailed,
  retrievePolicy,
  retrievePolicySuccess,
  retrievePolicyFailed,
  inviteUser,
  inviteUserSuccess,
  inviteUserFailed,
  retrieveCustomerUsers,
  retrieveCustomerUsersSuccess,
  retrieveCustomerUsersFailed,
} = customerSlice.actions;

export default customerSlice.reducer;
