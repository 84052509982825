import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography } from '@material-ui/core';

import { resetNewPassword as resetNewPasswordAction } from 'containers/Auth/authSlice';

import ResetForgotPasswordForm from './ResetForgotPasswordForm';

const useStyles = makeStyles((theme) => ({
  page: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 47,
    // background: `url('/img/construction.jpeg') no-repeat`,
    backgroundSize: 'cover',
    margin: '0 auto',
    maxWidth: '100%',
    backgroundAttachment: 'fixed',
  },
  pageLogo: {
    margin: '0 auto 47px',
    maxWidth: 183,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 560,
    margin: '0 auto 100px',
    padding: '39px 67px',
    transition: 'all ease 300ms',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 26px',
    },
    borderRadius: 24,
  },
  paperTitle: {
    fontWeight: 800,
    fontSize: 30,
  },
  paperSubTitle: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 37,
  },
}));

function ForgotPasswordConfirmation(props) {
  const { resetNewPassword } = props;
  const classes = useStyles();
  const { username, session } = useParams();

  function handleSubmit(values) {
    resetNewPassword(values);
  }

  return (
    <Container component="main" className={classes.page}>
      <img className={classes.pageLogo} src="/img/logo-alt.png" alt="4Surity" />
      <Paper elevation={1} className={classes.paper}>
        <Typography
          component="h1"
          variant="h4"
          gutterBottom
          className={classes.paperTitle}>
          Reset password
        </Typography>
        <ResetForgotPasswordForm
          initialValues={{
            session,
            Username: decodeURIComponent(username),
          }}
          onSubmit={handleSubmit}
        />
      </Paper>
    </Container>
  );
}

ForgotPasswordConfirmation.propTypes = {
  resetNewPassword: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  resetNewPassword: (values) => dispatch(resetNewPasswordAction(values)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(ForgotPasswordConfirmation);
