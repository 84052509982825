import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { apiMiddleware } from 'redux-api-middleware';
// import authMiddleware from 'middleware/authMiddleware';
import notifications from 'components/Notifier/reducer';
import authSaga from 'containers/Auth/saga';
import authReducer from 'containers/Auth/authSlice';
import customerReducer from './containers/Customers/customerSlice';
import customerSaga from './containers/Customers/saga';

// import userSaga from 'components/User/saga';

import history from './common/history';

const sagaMiddleware = createSagaMiddleware();
const router = routerMiddleware(history);

const rootReducer = combineReducers({
  form,
  notifier: notifications,
  auth: authReducer,
  customer: customerReducer,
  router: connectRouter(history),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware, apiMiddleware, router],
});

sagaMiddleware.run(authSaga);
sagaMiddleware.run(customerSaga);

export default store;
