import { withStyles } from '@material-ui/core';

const styles = () => ({
  '@global': {
    body: {
      fontFamily: `'NanumSquare', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
      backgroundColor: 'rgba(62,69,81,.7)',
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
